import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c55c2a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("form", {
              class: "login-container",
              onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.login(_ctx.form)), ["enter"])),
              onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.login(_ctx.form)), ["prevent"]))
            }, [
              _createVNode(_component_Logo),
              _createVNode(_component_ion_item, { lines: "full" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "fixed" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Username")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    name: "username",
                    modelValue: _ctx.username,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                    id: "username",
                    type: "text",
                    required: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "fixed" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Password")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    name: "password",
                    modelValue: _ctx.password,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                    id: "password",
                    type: "password",
                    required: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_button, {
                  type: "submit",
                  color: "primary",
                  fill: "outline",
                  expand: "block"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Login")), 1)
                  ]),
                  _: 1
                })
              ])
            ], 32)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}